<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
									<div>
										<router-link :to="{path: '/custom_form'}"><i class="el-icon-back" style="font-size:30px;"></i></router-link>
									</div>
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.answers_of_clients") }}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.user.show">
                                {{ columns.user.title }}
                            </th>

									 <th v-if="columns.ip_address.show">
                                {{ columns.ip_address.title }}
                            </th>

									 <th v-if="columns.source.show">
                                {{ columns.source.title }}
                            </th>

									 <th v-if="columns.deal_id.show">
										 {{ columns.deal_id.title }}
                            </th>

									 <th v-for="(header, index) in list.header" :key="'header-'+index">
										{{ header.label }}
									 </th>

									 <th v-if="list['type_form']">
                                {{ $t('message.edit_m', {
												m: $t('message.deal')
										  }) }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.user.show">
                                <crm-input
                                    :placeholder="columns.user.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"
                                ></crm-input>
                            </th>
									 <th v-if="columns.ip_address.show">
                                <crm-input
                                    :placeholder="columns.ip_address.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.ip_address"
                                ></crm-input>
                            </th>
									   <th v-if="columns.source.show">
                                <crm-input
                                    :placeholder="columns.source.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.source"
                                ></crm-input>
                            </th>

									   <th v-if="columns.deal_id.show">
                                <crm-input
                                    :placeholder="columns.deal_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal_id"
                                ></crm-input>
                            </th>

									  <th v-for="(header, index) in list.header" :key="'header-'+index">
		
									 </th>
									 <th v-if="list['type_form']">
                              
                            </th>
							   
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="clientForm in list.clientForms"
                            :key="clientForm.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ clientForm.id }}
                            </td>
									  <td v-if="columns.user.show">
                                {{ clientForm.user.name }}
                            </td>
									<td v-if="columns.ip_address.show">
										{{ clientForm.ip_address }}
									</td>

                            <td v-if="columns.source.show">
                                {{ clientForm.source ? clientForm.source.name : '' }}
                            </td>

									  <td v-if="columns.deal_id.show">
                                {{ clientForm.deal_id ? $t('message.n') + ' ' + clientForm.deal_id : $t('message.no')}}
                            </td>

									 <td v-for="(data, index) in clientForm.form_data" :key="'value-' + index">
											<div v-if="data.name == 'product_ids'">
												<div v-if="clientForm.products.length > 0">
													<el-popover placement="left" width="500" trigger="click">
														<el-table :data="clientForm.products">
															<el-table-column width="80" property="id" label="ID"></el-table-column>
															<el-table-column width="300" property="name" :label="$t('message.name')"></el-table-column>
														</el-table>
														<el-button  slot="reference" size="mini"><i class="el-icon-box"></i> / {{clientForm.products.length}}</el-button>
													</el-popover>
												</div>
												<div v-else>{{ $t('message.no') + ' ' + $t('message.products') }}</div>
											</div>
											<div v-else-if="data.type =='el-upload'">
												<el-button v-if="clientForm.files.length > 0" type="primary" @click="showFiles(clientForm)" icon="el-icon-view" round size="mini">{{$t('message.files')}}</el-button>
												<span v-else> {{ $t('message.no') + ' ' + $t('message.files') }} </span>
											</div>
											<div v-else-if="data.type == 'el-checkbox'">
												<span v-for="(item, index) in data.value" :key="'checkbox-'+index">
													{{ item + ', ' }}
												</span>
											</div>
											<div v-else-if="data.name == 'type_id'">{{ data.value == 1 ? $t('message.individual') : $t('message.legal') }}</div>
											<div v-else>{{ data.value }}</div>
									 </td>

									 <td v-if="list['type_form']">
                              <el-button v-if="clientForm.deal_id" @click="updateDeal(clientForm)" type="primary" size="mini"><i class="el-icon-edit"></i></el-button>
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ clientForm.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ clientForm.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
										<crm-settings
											:name="$options.name"
											:model="clientForm"
											:actions="actions"
											:permissionDestroy="'custom_form.delete'"
											@delete="destroy"
										></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>
				<div class="app-modal app-modal__full bg-seriq">
					<el-drawer ref="drawerDealCreate" :with-header="false" :class="mode ? 'l-modal-style' : 'd-modal-style'"
						class="create-task-modal " :visible.sync="drawerDealCreate" @opened="drawerOpened('drawerDealCreateChild')"
						@closed="drawerClosed('drawerDealCreateChild')" size="96%">
						<AppModal ref="drawerDealCreateChild" :board_id="null" :selectedDeal="selectedItem" :dealCreate="false"
						@addModalEdit="closeAddModel()" drawer="drawerDealCreate" />
					</el-drawer>
        		</div>
				<el-dialog :title="$t('message.files')" :visible.sync="centerDialogVisible" width="80%" center :append-to-body="true">
					<el-row :gutter="20"  v-if="files && files.length > 0">
						<el-col :span="12" v-for="file in files" :key="file.id">
							<div class="mb-2 d-flex" style="border: 1px solid #dee3e7;">
								<div class="mm-image-container" style="cursor:pointer;" @click="showImage(file)">
									<el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes((/[^.]*$/.exec(file.name)[0]))"
										style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);"
										class="p-1"
										:src="file.path"
										fit="fill">
									</el-image>
									<div v-else class="text-center">
									<i class="el-icon-document-copy mt-2" style="font-size: 50px;"></i>
									<span class="d-block mt-2 font-weight-bold" >{{/[^.]*$/.exec(file.name)[0]}}</span>
									</div>
								</div>
								<div class="ml-2" >
									<h3>{{file.name }}</h3>
									<div class="d-flex">
										<!-- :download="file.name" target="_blank" file.path-->
										<a href="#"  class="mr-3"> 
											<el-button type="primary" @click="downloadFile(client_form_id, file.name, 'clientFormFiles')"  icon="el-icon-download" size="medium"></el-button>
										</a>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-dialog>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import AppModal from "../deals/components/add-modal";
export default {
	name: "clientForm",
	mixins: [list],
	components: {AppModal},
   data() {
		return {
			appURL: process.env.VUE_APP_BASE_URL_FRONT,
			selectedItem: {},
			drawerDealCreate: false,
			centerDialogVisible: false,
			files: [],
			dialogPictureVisible: false,
			selectedImage: {},
			client_form_id: null
		};
	},
	computed: {
		...mapGetters({
			list: "clientForm/list",
			columns: "clientForm/columns",
			pagination: "clientForm/pagination",
			filter: "clientForm/filter",
			sort: "clientForm/sort",
			mode: "MODE",
			authUser: 'auth/user'
		}),
		actions: function () {
			return ["edit", "delete"];
		},
    },
   methods: {
		...mapActions({
			updateList: "clientForm/index",
			setPagination: "clientForm/setPagination",
			updateSort: "clientForm/updateSort",
			updateFilter: "clientForm/updateFilter",
			updateColumn: "clientForm/updateColumn",
			updatePagination: "clientForm/updatePagination",
			show: "clientForm/show",
			empty: "clientForm/empty",
			delete: "clientForm/destroy",
			refreshData: "clientForm/refreshData",
			downloadDealFile: 'deals/downloadDealFile'
		}),
		fetchData() {
         let query = {...this.filter, ...this.pagination, ...this.sort, custom_form_id: this.$route.params.custom_form_id};
			if (!this.loadingData) {
				this.loadingData = true;
				this.updateList(query).then(res => {
					this.loadingData = false;
				}).catch(err => {
					this.loadingData = false;
				});
			}
      },
		updateDeal(clientForm){
			this.drawerDealCreate = true;
			this.selectedItem['client_form_id'] = clientForm.id;
			this.selectedItem['id'] = clientForm.deal_id;
		},
		showFiles(client_form){
			this.centerDialogVisible = true;
			this.files = client_form.files;
			this.client_form_id = client_form.id;
		},
		showImage(file){
        if(file.name){
			   let link = document.createElement('a');
            link.href = file.path;
				link.target = '_blank';
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
      },
		downloadFile(deal_id, fileName, folder) {
			let data = { deal_id: deal_id, file: fileName, folder: folder};
			this.downloadDealFile(data).then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((err) => {
					this.$alert(err);
				});
      },
   },
   beforeRouteLeave (to, from, next) {
		this.$store.commit('clientForm/EMPTY_LIST');
		this.files = [];
		next()
   },
};
</script>

